import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import htmlParser from 'html-react-parser';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

const Wysiwyg = ({ text, transform, className }) => (
  <div className={cx('Wysiwyg', className)}>{htmlParser(text, { replace: transform })}</div>
);

Wysiwyg.defaultProps = {
  transform: () => {},
  className: '',
};

Wysiwyg.propTypes = {
  text: PropTypes.string.isRequired,
  transform: PropTypes.func,
  className: PropTypes.string,
};

export default Wysiwyg;
